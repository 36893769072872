<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-container fluid>
      <b-card>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group>
              <label for="datepicker">{{ $t("choose_date") }}</label>
              <b-input-group>
                <flat-pickr
                  id="datepicker"
                  v-model="pickup_date"
                  :placeholder="$t('select_date')"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'D F j, Y',
                  }"
                  @input="fetchOrders"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col>
          <b-card>
            <OrderFilter
              :filter-labels="filterLabels"
              @switchFilter="switchFilter"
            />
            <MyTable
              :columns="columns"
              :rows="rows"
              :is-loading="isLoading"
              :checkbox-enabled="true"
            >
              <template v-slot="{ props }">
                <span v-if="props.column.field === 'id'">
                  <b-button
                    variant="primary"
                    block
                    class="w-100"
                    @click="orderSummary(props.row.id)"
                  >
                    {{ props.row.id }}
                  </b-button>
                </span>
                <span v-else-if="props.column.field === 'chartered_orders'">
                  {{ props.row.chartered_orders[0]["recipient"]["complete"] }}
                </span>

                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    :variant="
                      props.row.status.value == 17 && props.row.user_quote
                        ? 'red'
                        : props.row.status.value == 12 && !props.row.user_quote
                        ? 'turquoise'
                        : props.row.status.value === 14
                        ? 'pink'
                        : props.row.status.color_name
                    "
                    class="p-1 w-100"
                    @click="showStatusModal(props.row)"
                  >
                    {{ getStatusText(props.row) }}
                  </b-badge>
                </span>

                <div
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-center"
                >
                  <div v-if="filter === 'request_for_quotation'">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="orderSummary(props.row.id)">
                        <span>View Order</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="quoteOrder(props.row)">
                        <span>Quote Order</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-else-if="filter === 'active'">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="orderSummary(props.row.id)">
                        <span>View Order</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="updateStatus(props.row.id)">
                        <span>Update Status</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-else-if="filter === 'history'">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="orderSummary(props.row.id)">
                        <span>View Order</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </MyTable>
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        v-model="isQuoteModalVisible"
        :title="`Quotation for order ID #` + selected_id"
        centered
        hide-footer
        no-close-on-backdrop
      >
        <QuotationForm
          :order_id="selected_id"
          :quotation="selected ? selected.user_quote : {}"
          :has-insurance="
            selected ? (selected.insurance === 'Yes' ? true : false) : false
          "
          @success="handleQuotationSuccess"
        />
      </b-modal>
      <b-modal
        v-model="isUpdateModalVisible"
        hide-header
        centered
        no-close-on-backdrop
      >
        <div>
          <v-select
            v-model="update.status"
            :options="optionsStatus"
            :reduce="(optionsStatus) => optionsStatus.value"
            label="text"
            placeholder="Update Status"
          />
        </div>
        <template #modal-footer="{ cancel }">
          <b-button variant="warning" @click="cancel('cancel')">
            Cancel</b-button
          >
          <b-button variant="primary" @click="callUpdate()">
            Update Status
          </b-button>
        </template>
      </b-modal>
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        />
        <h3 class="mt-3">Loading... Please wait.</h3>
      </div>
    </template>
  </b-overlay>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import { OrderFilter, MyTable } from "@/components";
import vSelect from "vue-select";
import { required } from "@validations";
import QuotationForm from "@/components/FleetForwarding/QuotationForm.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    OrderFilter,
    vSelect,
    QuotationForm,
  },

  data() {
    return {
      isLoading: false,
      newStatus: "",
      isUploadModalVisible: false,
      isQuoteModalVisible: false,
      isUpdateModalVisible: false,
      isLoading: false,
      filter: "request_for_quotation",
      optionsStatus: [
        { text: "Picked Up", value: "picked_up" },
        { text: "Arrived at origin port", value: "arrived_at_origin_port" },
        { text: "In Transit", value: "in_transit" },
        {
          text: "Arrived at destination port",
          value: "arrived_at_destination_port",
        },
        { text: "Out for Delivery", value: "out_for_delivery" },
        { text: "Delivered", value: "delivered" },
      ],
      filterLabels: [
        { label: "Quotation Request", value: "request_for_quotation" },
        { label: "Active", value: "active" },
        { label: "History", value: "history" },
      ],
      columns: [
        {
          label: "Order ID",
          field: "id",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
        {
          label: "Lorry Size",
          field: "lorry",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
        {
          label: "Sender Address",
          field: "sender.complete",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
        {
          label: "Receiver Address",
          field: "chartered_orders",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      selectedFiles: [{}],
      selected: null,
      pickup_date: new Date().toJSON().slice(0, 10),
      required,
      update: {},
      selected_id: null,
    };
  },

  async created() {
    this.fetchOrders();
  },

  methods: {
    getStatusText(row) {
      const statusValue = row.status.value;
      let statusText;
      switch (statusValue) {
        case 17:
          statusText = row.user_quote ? "Quoted" : "For Quote";
          break;
        case 12:
          statusText = row.user_quote ? "Quoted" : "For Quote";
          break;
        case 2:
          statusText = "Accepted";
          break;
        case 3:
          statusText = "Picked Up";
          break;
        case 4:
          statusText = "Arrived at Origin";
          break;
        case 5:
          statusText = "In Transit";
          break;
        case 6:
          statusText = "Arrived at Destination Port";
          break;
        case 7:
          statusText = "Out For Delivery";
          break;
        case 8:
          statusText = "Delivered";
          break;
        case 9:
          statusText = "Request For Cancellation";
          break;
        case 10:
          statusText = "Cancelled";
          break;
        default:
          statusText = row.status.text;
      }
      return statusText;
    },
    handleFileChange(index) {
      const file = this.selectedFiles[index];
    },

    addFile() {
      this.selectedFiles.push(null);
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    async fetchOrders() {
      this.isLoading = true;
      const response = await this.$http
        .get(
          `order-smarttruck-chartered?orders_for=smartchartered&
          order_status=${this.filter}&pickup_date=${this.pickup_date}`
        )
        .then((response) => {
          if (response.data?.length > 0) {
            this.rows = [...response.data];
          } else {
            this.rows = [];
          }
          this.isLoading = false;
        });
    },
    orderSummary(row) {
      this.$router.push({
        name: "chartered-truck-summary",
        params: {
          id: row,
        },
      });
    },
    switchFilter(filter) {
      this.filter = filter;
      this.fetchOrders();
    },
    quoteOrder(row) {
      this.selected = row;
      this.selected_id = row.id;
      this.isQuoteModalVisible = true;
    },
    async showStatusModal(order) {
      this.$refs.status.show();
      this.order = order;
    },
    async updateStatus(id) {
      this.update.id = id;
      this.isUpdateModalVisible = true;
    },
    async callUpdate() {
      this.isLoading = true;
      const response = await this.$http.get(
        `update_order_status?order_id=${this.update.id}&status=${this.update.status}`
      );
      if (response) {
        this.isUpdateModalVisible = false;
        this.fetchOrders();
      } else {
        this.isLoading = false;
      }
    },
    async handleQuotationSuccess(title, message) {
      await this.fetchOrders();
      this.isQuoteModalVisible = false;
      this.$swal({
        icon: "success",
        title,
        text: message,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    uploadDocument() {
      this.isUploadModalVisible = true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
